<template>
    <v-card :loading="loading">
        <v-card-title class="text-h5">
            <v-btn small icon @click="close" color="primary" class="mr-2">
                <v-icon>
                    mdi-close
                </v-icon>
            </v-btn>
            <h3>
                GENERATE INFORM
            </h3></v-card-title
        >
        <v-divider class="ml-4 mr-5" />
        <v-card-text class="pt-4">
            <v-form v-model="valid">
                <v-row no-gutters>
                    <v-col cols="12" class="pt-4">
                        <v-menu
                            ref="menu"
                            v-model="menu"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            transition="scale-transition"
                            offset-y
                            min-width="auto"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <v-combobox
                                    class="py-0 my-0"
                                    v-model="dateRangeText"
                                    color="primary"
                                    label="Date range"
                                    readonly
                                    flat
                                    hide-details
                                    v-bind="attrs"
                                    v-on="on"
                                    prepend-icon="mdi-calendar-blank-outline"
                                ></v-combobox>
                            </template>
                            <v-date-picker v-model="dates" range scrollable>
                            </v-date-picker>
                        </v-menu>
                    </v-col>
                </v-row>
            </v-form>
        </v-card-text>
        <v-spacer></v-spacer>
        <v-card-actions class="pb-4">
            <v-spacer></v-spacer>
            <v-btn
                @click="generateXLS"
                color="primary"
                :loading="loading"
                rounded
            >
                GENERATE
            </v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
import API from '@/services/api'
import * as XLSX from 'xlsx/xlsx.mjs'
import { mapMutations } from 'vuex'
import moment from 'moment'

export default {
    name: 'PartialDeliveriesForReport',
    props: {
        users: {
            type: Array,
            required: true,
        },
    },
    data: () => ({
        loading: false,
        dates: [],
        valid: false,
        menu: false,
        startDate: '',
        endDate: '',
    }),
    computed: {
        dateRangeText() {
            return this.dates.join(' ~ ')
        },
    },
    methods: {
        ...mapMutations(['setErrorItems']),
        formatDate(seconds) {
            return moment.unix(seconds).format('YYYY-MM-DD HH:mm:ss')
        },
        findUserName(userId) {
            if (this.users && this.users.length > 0) {
                const user = this.users.find(user => user.id == userId)
                if (user) {
                    return user.name
                } else {
                    return undefined
                }
            } else {
                return undefined
            }
        },
        async generateXLS() {
            try {
                this.loading = true
                this.setDate()
                let boms = await API.getBOMPartialDeliveriesForReport({
                    startDate: this.startDate,
                    endDate: this.endDate,
                })
                const reportData = []

                for (const bom of boms) {
                    let row = {}

                    const {
                        bomCode,
                        deliveredBy,
                        date,
                        itemCode,
                        itemDescription,
                        operator,
                        notes,
                        qty,
                        requestedOn,
                        requestedFor,
                    } = bom
                    ;(row.bom_code = bomCode),
                        (row.item_code = itemCode),
                        (row.description = itemDescription
                            ? itemDescription
                            : '')
                    row.delivered_by = this.findUserName(deliveredBy)
                    row.operator =
                        typeof operator == 'string'
                            ? this.findUserName(operator)
                            : operator.name
                    row.requested_on = requestedOn
                        ? this.formatDate(requestedOn._seconds)
                        : ''
                    row.requested_for = requestedFor
                        ? requestedFor.split('T')[0]
                        : ''
                    row.delivery_date = this.formatDate(date._seconds)
                    row.qty = qty
                    row.notes = notes
                    reportData.push(row)
                    row = {}
                }

                // only array possible
                const reportWS = XLSX.utils.json_to_sheet(reportData)
                // A workbook is the name given to an Excel file
                const wb = XLSX.utils.book_new() // make Workbook of Excel
                // Workbook contains one or more worksheets
                XLSX.utils.book_append_sheet(wb, reportWS, 'report') // sheetAName is name of Worksheet
                // export Excel file
                XLSX.writeFile(wb, `requestedBOMS.xlsx`) // name of the file is 'book.xlsx'
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
        setDate() {
            if (this.dates[0] > this.dates[1]) {
                this.startDate = this.dates[1]
                this.endDate = this.dates[0]
            } else {
                this.startDate = this.dates[0]
                this.endDate = this.dates[1]
            }
            this.menu = false
        },
        close() {
            this.$emit('close')
        },
    },
}
</script>

<style></style>
